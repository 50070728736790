import * as React from 'react'
import { Modal, Button } from 'react-bootstrap'
import { ConfirmationModalProps } from './useUnsavedChangesModal'

const ConfirmationdModal: React.FC<ConfirmationModalProps> = (props) => {
    return (
        <Modal centered show={true} onHide={props.onClose}>
            <Modal.Header closeButton>
                <Modal.Title as={'h5'}>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{props.content}</Modal.Body>
            <hr />
            <Modal.Footer>
                <Button variant="outline-secondary" onClick={props.onClose}>
                    {props.closeBtnLabel}
                </Button>
                <Button
                    className={'btn-primary'}
                    variant="danger"
                    onClick={props.onConfirm}
                >
                    {props.confirmBtnLabel}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ConfirmationdModal
