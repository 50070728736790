// eslint-disable-next-line @typescript-eslint/no-var-requires
const axios = require('axios')
// eslint-disable-next-line @typescript-eslint/no-var-requires
const { setupCache, buildWebStorage } = require('axios-cache-interceptor')
const cachedAxios = setupCache(
    axios.create({
        baseURL:
            process.env && process.env.REACT_APP_KEDO_API_BASE_URL
                ? process.env.REACT_APP_KEDO_API_BASE_URL
                : '/api',
    }),
    {
        methods: ['get'],
        storage: buildWebStorage(localStorage, 'axios-cache:'),
        headerInterpreter: () => {
            return 300
        },
    }
)

class Api {
    constructor() {
        this.base_url =
            process.env && process.env.REACT_APP_KEDO_API_BASE_URL
                ? process.env.REACT_APP_KEDO_API_BASE_URL
                : '/api'
    }

    getContentListEndpoint(dossierId, displayId, mode, listId) {
        let url = `/dossier/${dossierId}/contentlist/${displayId}`
        if (mode === 'set') {
            return url + '/set'
        }

        return url + `/${mode}/${listId}`
    }

    getBaseUrl() {
        return this.base_url
    }

    getGoogleConnect() {
        return this.base_url + '/connect/google'
    }

    getAzureConnect() {
        return this.base_url + '/connect/azure'
    }

    getPushNotificationEndpoint(id) {
        return '/pushsubscription' + (id ? '/' + id : '')
    }

    getSubscriptionPlanEndpoint() {
        return '/subscriptionplan'
    }

    getOwnSubscriptionEndpoint() {
        return '/subscription/own'
    }

    getOrderEndpoint() {
        return '/order'
    }

    getOwnContractEndpoint() {
        return '/contract/own'
    }

    getReactivateSubscriptionEndpoint(subId) {
        return '/subscription/own/' + subId + '/reactivate'
    }

    getDisplayCustomViewEndpoint(id) {
        return '/displaycustomview' + (id ? '/' + id : '')
    }

    getDisplayPositionCredentialsEndpoint() {
        return '/displayposition/credentials'
    }
    getIsCredentialAllowedEndpoint() {
        return '/credential_allowed'
    }

    getMyPageBlockEndpoint(id) {
        return '/mypageblock' + (id ? '/' + id : '')
    }

    getOwnActiveSubscriptionEndpoint() {
        return '/subscription/own/active'
    }

    getOwnContractUsageEndpoint() {
        return '/contract/own/usage'
    }

    getOwnContractCancelEndpoint() {
        return '/contract/own/cancel'
    }

    getOwnActiveContractEndpoint() {
        return '/contract/own/active'
    }

    getRequestLogEndpoint() {
        return '/log'
    }

    getApplyTemplateEndpoint(templateId, state) {
        return '/template/apply/' + templateId + '/' + state
    }

    getKedocxEndpoint() {
        return '/kedocx'
    }

    getEnvironmentModuleEndpoint(id) {
        return '/environmentmodule' + (id ? '/' + id : '')
    }

    getModuleEndpoint() {
        return '/module'
    }

    getDossierQueryEndpoint(id) {
        return '/dossierquery' + (id ? '/' + id : '')
    }

    getCredentialEndpoint() {
        return '/credential'
    }

    getSubscriptionRestrictionEndpoint() {
        return '/subscriptionrestriction'
    }

    getDefDossierPermissionEndpoint(id) {
        if (id) {
            return '/defdossierpermission/' + id
        }
        return '/defdossierpermission'
    }

    getLocaleEndpoint() {
        return '/locale'
    }

    getContentDossierSubscriptionsEndpoint() {
        return '/dossier/subscriptions'
    }

    getContentDossierFavoriteEndpoint(dossierId) {
        if (!dossierId) {
            return '/dossier/favorite'
        }

        return '/dossier/' + dossierId + '/favorite'
    }

    getContentDossierSubscriptionEndpoint(dossierId, subscribe) {
        return (
            '/dossier/' +
            dossierId +
            '/' +
            (subscribe === true ? 'subscribe' : 'unsubscribe')
        )
    }

    getContentDossierEndpoint(id) {
        return '/dossier' + (id ? '/' + id : '')
    }

    getContentDossierLinkEndpoint() {
        return '/contentdossierlink'
    }

    getEventEndpoint(id) {
        return '/event' + (id ? '/' + id : '')
    }

    getMessageEndpoint() {
        return '/message'
    }

    getAdminTemplateTranslationEndpoint(id) {
        return '/admin/template/' + id + '/translation'
    }

    getAdminTemplateEndpoint() {
        return '/admin/template'
    }

    getUserTwoFactorEndpoint(userId) {
        return '/user/' + userId + '/twofactor'
    }

    getUserProfileEndpoint(identifier) {
        return '/user/' + identifier + '/profile'
    }

    getEnvironmentUserRoleEndpoint(userId, envId) {
        return '/user/' + userId + '/roles/' + envId
    }
    getUserEndpoint(userId) {
        return '/user' + (userId ? '/' + userId : '')
    }

    getToewijzingenConnectEndpoint(dossierId) {
        return '/module/ijwiwmo/toegewezenproduct/connect/' + dossierId
    }

    getToewijzingenEndpoint() {
        return '/module/ijwiwmo/toegewezenproducten'
    }

    getMassUpdateEndpoint() {
        return '/dossier/massupdate'
    }

    getFileEndpoint() {
        return '/file'
    }

    getRoleEndpoint(id) {
        return '/role' + (id ? '/' + id : '')
    }

    getImportEndpoint() {
        return '/import'
    }

    getDataFieldEndpoint(id) {
        return '/defdossierdeffield' + (id ? '/' + id : '')
    }

    getDefDossierDefFieldEndpoint(id) {
        return this.getDataFieldEndpoint(id)
    }

    getUpdateBacklinkDefDossierLinkEndpoint() {
        return '/defdossierlink/backlink'
    }

    getDefDossierLinkEndpoint(id) {
        return '/defdossierlink' + (id ? '/' + id : '')
    }

    getDefFieldListEndpoint() {
        return '/deffieldlist'
    }

    getDefFieldEndpoint(id) {
        return '/deffield' + (id ? '/' + id : '')
    }

    getDisplayItemEndpoint(id) {
        return '/displayitem' + (id ? '/' + id : '')
    }

    getDisplayPositionEndpoint(id) {
        return '/displayposition' + (id ? '/' + id : '')
    }

    getDataStructureEndpoint(id) {
        return '/datastructure' + (id ? '/' + id : '')
    }

    getDossierLogEndpoint() {
        return '/dossierlog'
    }

    getDefDossierEndpoint(id) {
        return '/defdossier' + (id ? '/' + id : '')
    }

    getContractEndpoint() {
        return '/contract'
    }

    getEnvironmentLayoutEndpoint(id) {
        return '/environment/' + id + '/layout'
    }

    getEnvironmentEndpoint(id) {
        return '/environment' + (id ? '/' + id : '')
    }

    getSettingEndpoint(envId, settingId) {
        if (!envId) {
            throw ''
        }
        return (
            '/environment/' +
            envId +
            '/setting' +
            (settingId ? '/' + settingId : '')
        )
    }

    getUserAvatarEndpoint(id, avatar, size) {
        if (size > 0 && size < 100) {
            return `/images/user/${id}_${avatar}_64.webp`
        }
        return `/images/user/${id}_${avatar}_200.webp`
    }

    getContractUserEndpoint() {
        return '/contractuser'
    }

    getEnvironmentUserEndpoint() {
        return '/environmentuser'
    }

    getEnvironmentLocaleEndpoint() {
        return '/environmentlocale'
    }

    getSecureShareEndpoint(id) {
        return '/dossier/' + id + '/secureshare'
    }

    getUserMeEndpoint() {
        return '/user/me'
    }

    getWebhookEndpoint() {
        return '/webhook'
    }

    /**
     * @param response
     * @returns {{generic: string}|{Object}}
     */
    getErrorFromApiCall(response) {
        if (
            response &&
            response.status === 400 &&
            response.data &&
            response.data.errors
        ) {
            return response.data.errors
        } else if (response && response.status === 403) {
            return { generic: '403_general_message' }
        }

        return { generic: 'Something went wrong' }
    }

    /**
     * @param {String} endpoint
     * @param {Object} options
     *
     * @returns {Promise<AxiosResponse<any>>}
     */
    getFull(endpoint, options, refreshToken = true) {
        this.getAndSetTokenStorageData()
        if (refreshToken === true) this.checkTokenForExpiryAndRenew()

        return this.__get(endpoint, options)
    }

    /**
     * @param {String} endpoint
     * @param {Object} options
     *
     * @returns {Promise<AxiosResponse<any>>}
     */
    getAsync = async (endpoint, options = {}, refreshToken = true) => {
        this.getAndSetTokenStorageData()
        if (refreshToken === true) this.checkTokenForExpiryAndRenew()

        if (!options || options.disableSecurity !== true) {
            options.withCredentials = true
        }

        return await axios.get(this.base_url + endpoint, options).catch()
    }

    /**
     * @param {String} endpoint
     * @param {Object} options
     *
     * @returns {Promise<AxiosResponse<any>>}
     */
    getCached = async (endpoint, options = {}, refreshToken = true) => {
        this.getAndSetTokenStorageData()
        if (refreshToken === true) this.checkTokenForExpiryAndRenew()

        if (!options || options.disableSecurity !== true) {
            options.withCredentials = true
        }

        return await cachedAxios.get(endpoint, options).catch()
    }

    /**
     * @param {String} endpoint
     * @param {Object} options
     *
     * @returns {Promise<AxiosResponse<any>>}
     */
    async get(endpoint, options, refreshToken = true) {
        this.getAndSetTokenStorageData()
        if (refreshToken === true) this.checkTokenForExpiryAndRenew()

        return await this.__get(this.base_url + endpoint, options)
    }

    /**
     * @param {String}  endpoint
     * @param {Object}  data
     * @param {Object}  options
     * @param {boolean} refreshToken
     * @returns {Promise<AxiosResponse<any>>}
     */
    async post(endpoint, data, options = {}, refreshToken = true) {
        this.getAndSetTokenStorageData()
        if (refreshToken === true) this.checkTokenForExpiryAndRenew()

        return await this.__post(this.base_url + endpoint, data, options)
    }

    /**
     * @param {String}  endpoint
     * @param {Object}  data
     * @param {Object}  options
     * @param {boolean} refreshToken
     * @returns {Promise<AxiosResponse<any>>}
     */
    put(endpoint, data, options, refreshToken = true) {
        this.getAndSetTokenStorageData()
        if (refreshToken === true) this.checkTokenForExpiryAndRenew()

        return this.__put(this.base_url + endpoint, data, options)
    }

    /**
     * @param {String}  endpoint
     * @param {Object}  options
     * @param {boolean} refreshToken
     * @returns {*}
     */
    delete(endpoint, options = {}, refreshToken = true) {
        this.getAndSetTokenStorageData()
        if (refreshToken === true) this.checkTokenForExpiryAndRenew()
        options.withCredentials = true

        return axios.delete(this.base_url + endpoint, options).catch()
    }

    /**
     * @param {Object}  endpoint
     * @param {Object}  data
     * @param {Object}  options
     * @param {boolean} refreshToken
     * @returns {Promise<AxiosResponse<any>>}
     */
    patch(endpoint, data, options = {}, refreshToken = true) {
        this.getAndSetTokenStorageData()
        if (refreshToken === true) this.checkTokenForExpiryAndRenew()

        return this.__patch(this.base_url + endpoint, data, options)
    }

    /**
     * @param {String}  endpoint
     * @param {Object}  data
     * @param {Object}  options
     * @returns {Promise<AxiosResponse<any>>}
     */
    postRaw(endpoint, data, options = {}) {
        endpoint = this.base_url + endpoint
        options.withCredentials = true
        return axios.post(endpoint, data, options).catch()
    }

    /**
     * Checks if we have a token else we need to login first
     * @returns {boolean}
     */
    hasToken() {
        this.getAndSetTokenStorageData()

        return this.tokenData != null
    }

    /**
     * Checks if a token is expired
     * @returns {boolean}
     */
    isTokenExpired() {
        if (this.hasToken() !== true) {
            return true
        }

        var now = new Date()

        return now.getTime() >= this.tokenData.exp * 1000
    }

    async __get(endpoint, options = {}) {
        if (!options || options.disableSecurity !== true) {
            options.withCredentials = true
        }

        return await axios.get(endpoint, options)
    }

    /**
     * @param endpoint
     * @param data
     * @param options
     * @returns {*}
     * @private
     */
    __patch(endpoint, data, options = {}) {
        options.withCredentials = true

        return axios.patch(endpoint, data, options)
    }

    __put(endpoint, data, options = {}) {
        options.withCredentials = true

        return axios.put(endpoint, data, options).catch()
    }

    async __post(endpoint, data, options = {}) {
        options.withCredentials = true

        return await axios.post(endpoint, data, options)
    }

    /**
     * Update token
     * @param {Object} tokenData Object from the jwt token response
     * @param {String} token
     * @param {String} refreshToken
     */
    updateToken(tokenData, token, refreshToken) {
        this.tokenData = tokenData
        this.token = token
        this.refreshToken = refreshToken

        //Store tokenData but don't store sensitive information
        localStorage.setItem('tokenData', JSON.stringify(tokenData))
    }

    checkTokenForExpiryAndRenew() {
        //No token, do nothing
        if (true !== this.hasToken()) {
            return
        }

        //Token is not about to expire.
        if (false === this.tokenAlmostExpired()) {
            return
        }

        this.__get(this.base_url + '/refresh_token')
            .then((response) => {
                if (response.data.iat && response.data.exp) {
                    this.tokenData.exp = response.data.exp
                    this.tokenData.iat = response.data.iat
                    localStorage.setItem(
                        'tokenData',
                        JSON.stringify(this.tokenData)
                    )
                }
            })
            .catch((error) => {
                if (
                    error.response &&
                    error.response.status &&
                    error.response.status === 401
                ) {
                    this.tokenData = null
                    localStorage.removeItem('tokenData')
                }
            })
    }

    /**
     * Check if token has a lifetime of equal or less then 5 minutes
     *
     * @returns {boolean}
     */
    tokenAlmostExpired() {
        var now = new Date()

        return now.getTime() + 5 * 60 * 1000 >= this.tokenData.exp * 1000
    }

    /**
     * Gets the storage tokendata from localstorage
     */
    getAndSetTokenStorageData() {
        //Token already set
        if (this.tokenData) {
            return
        }

        //No localstorage set
        if (!localStorage.getItem('tokenData')) {
            return
        }

        this.tokenData = JSON.parse(localStorage.getItem('tokenData'))
    }
}

export default Api
