import React, { isValidElement } from 'react'

interface ElementProps {
    children?: React.ReactNode
}

export function compareItem(element: string | React.ReactNode): string {
    const extractText = (child: React.ReactNode): string => {
        if (typeof child === 'string') {
            return child
        }
        if (Array.isArray(child)) {
            return child.map(extractText).join(' ')
        }
        if (isValidElement<ElementProps>(child)) {
            return extractText(child.props.children)
        }
        return ''
    }

    if (typeof element === 'string') {
        return element
    }
    if (Array.isArray(element)) {
        return element.map(extractText).join(' ')
    }
    if (isValidElement<ElementProps>(element)) {
        return extractText(element.props.children)
    }
    return ''
}
