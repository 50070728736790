import React from 'react'
import { Form } from 'react-bootstrap'
import DateTimeField from './DateTimeField'
import SelectRC, { Option } from 'rc-select'

import Avatar from '../Elements/Avatar.tsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserSlash } from '@fortawesome/pro-duotone-svg-icons/faUserSlash'
import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons/faChevronDown'
class DisplayItemDossierData extends React.Component {
    constructor(props) {
        super(props)

        this.innerRef = React.createRef()

        this.state = {
            users: [],
            usersSelected: [],
            tempArray: [],
            loading: false,
            timeout: null,
            value: null,
        }

        this.setSelectedUsers = this.setSelectedUsers.bind(this)
        this.handleUserInputChange = this.handleUserInputChange.bind(this)
        this.renderUserSearch = this.renderUserSearch.bind(this)
        this.getUserOptions = this.getUserOptions.bind(this)
    }

    getName(item) {
        if (item.text) {
            return item.text
        }

        if (item.label) {
            return item.label
        }

        if (!item.profile) {
            return item.username
        }

        return (
            item.profile.last_name +
            ', ' +
            item.profile.first_name +
            (item.profile.preposition && item.profile.preposition.length > 0
                ? ' ' + item.profile.preposition
                : '')
        )
    }

    getValue() {
        let dataType = this.props.item.settings.dossier_data

        if (dataType === 'created_by' || dataType === 'modified_by') {
            return this.state.usersSelected.map((item) => item.id)
        } else if (dataType === 'summary') {
            return this.state.value
        } else if (dataType === 'modified_at' || dataType === 'created_at') {
            return this.state.value
        }
    }

    getFormattedDate(value) {
        if (value.length <= 0) {
            return ''
        }

        let date = new Date(value)
        let dateTimeFormat = new Intl.DateTimeFormat('nl-NL', {
            dateStyle: 'medium',
            timeStyle: 'medium',
        })

        return dateTimeFormat.format(date)
    }

    renderUser(userData) {
        if (userData && userData.username) {
            return <a href={`/user/${userData.id}`}>{userData.username}</a>
        }

        return ''
    }

    fetchUserData(search) {
        const api = this.props.kedo.api()
        let params = {
            params: {},
        }

        params.params.environment = this.props.kedo.env().getEnvironmentId()

        if (search.length > 0) {
            params.params.name = search
        }
        this.setState({ loading: true })
        api.get(api.getUserEndpoint(), params)
            .then((response) => {
                let items = []
                for (let i = 0; i < response.data.results.length; i++) {
                    items.push({
                        label: response.data.results[i].username,
                        id: response.data.results[i].id,
                        value: response.data.results[i].id,
                    })
                }
                this.setState({
                    loading: false,
                    users: items,
                })
            })
            .catch((error) => {
                console.error('Error fetching user data:', error)
                this.setState({ loading: false })
            })
    }

    setSelectedUsers(items, dossierDataType) {
        if (items === null) {
            items = []
        }

        this.setState({ usersSelected: items })
        this.props.changeValue(dossierDataType, items)
    }

    handleUserInputChange(search) {
        if (this.state.timeout) clearTimeout(this.state.timeout)
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.timeout = setTimeout(() => {
            this.fetchUserData(search)
        }, 400)
    }

    getUserOptions(inputValue, callBack) {
        if (!inputValue && callBack) {
            callBack([])
        }

        let params = {
            params: {
                environment: this.props.kedo.env().getEnvironment().id,
                limit: 25,
            },
        }

        if (inputValue && inputValue.length > 0) params.params.name = inputValue

        if (this.state.showInactive !== true) {
            params.params.active = true
        }

        this.props.kedo
            .api()
            .get(this.props.kedo.api().getUserEndpoint(), params)
            .then((response) => {
                const tempArray = []
                response.data.results.forEach((user) => {
                    tempArray.push({
                        id: user.id,
                        value: user.id,
                        label: (
                            <span
                                style={
                                    !user.environment_user_active
                                        ? { color: 'gray' }
                                        : null
                                }
                            >
                                <Avatar
                                    key={user}
                                    kedo={this.props.kedo}
                                    user={{
                                        userId: user.id,
                                        username: user.username,
                                    }}
                                    options={{
                                        size: 25,
                                        showPresence: false,
                                    }}
                                />
                                &nbsp;{this.getName(user)}
                                {!user.environment_user_active ? (
                                    <FontAwesomeIcon icon={faUserSlash} />
                                ) : null}
                            </span>
                        ),
                    })
                })
                this.setState({ tempArray })
                if (callBack) callBack(tempArray)
            })
    }

    renderUserSearch(dossierDataType) {
        const filteredOptions = this.state.tempArray.filter(
            (option) =>
                !this.state.usersSelected.some(
                    (selected) => selected.value === option.value
                )
        )
        return (
            <SelectRC
                className="w-100 custom-select-react"
                mode={'multiple'}
                allowClear
                placeholder={this.props.kedo.t('Search') + '...'}
                value={this.state.usersSelected.filter((option) =>
                    this.state.usersSelected.some(
                        (item) => item.value === option.value
                    )
                )}
                onChange={(selectedItems) => {
                    const selectedOptions = selectedItems
                        .map((selectedItem) => {
                            const foundOption = this.state.usersSelected.find(
                                (option) => {
                                    return option.value === selectedItem
                                }
                            )
                            return foundOption
                        })
                        .filter(Boolean)
                    this.setSelectedUsers(selectedOptions, dossierDataType)
                }}
                suffixIcon={<FontAwesomeIcon icon={faChevronDown} />}
                notFoundContent={this.props.kedo.t('No items found')}
            >
                {filteredOptions.map((option) => (
                    <Option key={option.id} value={option.value}>
                        {option.label}
                    </Option>
                ))}
            </SelectRC>
        )
    }

    isSearch() {
        if (
            this.props.mode === 'search' ||
            this.props.mode === 'advanced-search'
        ) {
            return true
        }

        return false
    }

    render() {
        const dossierDataType = this.props.item.settings.dossier_data

        if (
            this.isSearch() === true &&
            (dossierDataType === 'created_by' ||
                dossierDataType === 'modified_by')
        ) {
            return this.renderUserSearch(dossierDataType)
        } else if (this.isSearch() && dossierDataType === 'summary') {
            return (
                <Form.Control
                    onChange={(event) =>
                        this.props.changeValue('summary', event.target.value)
                    }
                    onKeyPress={
                        this.props.onKeyPress
                            ? (event) =>
                                  this.props.onKeyPress(
                                      this.props.item.id,
                                      event
                                  )
                            : null
                    }
                    type="text"
                    value={this.props.value}
                />
            )
        } else if (this.isSearch() && dossierDataType === 'dossier_id') {
            return (
                <Form.Control
                    onChange={(event) =>
                        this.props.changeValue('dossierId', event.target.value)
                    }
                    onKeyPress={
                        this.props.onKeyPress
                            ? (event) =>
                                  this.props.onKeyPress(
                                      this.props.item.id,
                                      event
                                  )
                            : null
                    }
                    type="text"
                    value={this.props.value}
                />
            )
        } else if (
            this.isSearch() &&
            (dossierDataType === 'created_at' ||
                dossierDataType === 'modified_at')
        ) {
            return (
                <DateTimeField
                    {...this.props}
                    onChange={(event) =>
                        this.props.changeValue(
                            dossierDataType,
                            event.target.value
                        )
                    }
                    onKeyPress={
                        this.props.onKeyPress
                            ? (event) =>
                                  this.props.onKeyPress(
                                      this.props.item.id,
                                      event
                                  )
                            : null
                    }
                />
            )
        }

        if (!this.props.dossier || !this.props.dossier.id) {
            return ''
        }

        switch (dossierDataType) {
            case 'summary':
                return this.props.dossier.summary ?? null
            case 'dossier_id':
                return this.props.dossier.id
            case 'created_at':
                return this.getFormattedDate(this.props.dossier.created_at)
            case 'modified_at':
                return this.getFormattedDate(this.props.dossier.modified_at)
            case 'created_by':
                return this.renderUser(this.props.dossier.created_by)
            case 'modified_by':
                return this.renderUser(this.props.dossier.modified_by)
        }

        return ''
    }
}

export default DisplayItemDossierData
