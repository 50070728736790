import React from 'react'
import { Table } from 'react-bootstrap'

class ObjectCreatedInfo extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            item: this.props.item,
        }
    }

    render() {
        const kedo = this.props.kedo

        return (
            <Table striped>
                <tbody>
                    <tr>
                        <th>{kedo.t('Id')}</th>
                        <td>{this.props.item.id}</td>
                    </tr>
                    <tr>
                        <th>{kedo.t('Name')}</th>
                        <td>{this.props.item.name}</td>
                    </tr>
                    <tr>
                        <th>{kedo.t('Uuid')}</th>
                        <td>{this.props.item.uuid}</td>
                    </tr>
                    <tr>
                        <th>{kedo.t('Created by')}</th>
                        <td>
                            {this.props.item.created_by
                                ? this.props.item.created_by.username
                                : null}
                        </td>
                    </tr>
                    <tr>
                        <th>{kedo.t('Created at')}</th>
                        <td>
                            {this.props.item.created_at
                                ? kedo
                                      .utils()
                                      .dateFormat(this.props.item.created_at)
                                : null}
                        </td>
                    </tr>
                    <tr>
                        <th>{kedo.t('Modified by')}</th>
                        <td>
                            {this.props.item.modified_by
                                ? this.props.item.modified_by.username
                                : null}
                        </td>
                    </tr>
                    <tr>
                        <th>{kedo.t('Modified at')}</th>
                        <td>
                            {this.props.item.modified_at
                                ? kedo
                                      .utils()
                                      .dateFormat(this.props.item.modified_at)
                                : null}
                        </td>
                    </tr>
                </tbody>
            </Table>
        )
    }
}

export default ObjectCreatedInfo
