import React from 'react'
import { Alert, Button, Col, Form, Modal, Row, Table } from 'react-bootstrap'
import Loading from '../../Elements/Loading/LoadingData'
import Pagination from '../../Elements/Pagination'
import ItemCounter from '../../Elements/ItemCounter'
import SelectRC, { Option } from 'rc-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/pro-duotone-svg-icons/faExclamationCircle'
import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons/faChevronDown'

class ContentDossierLog extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            logs: [],
            searchType: null,
            searchUser: null,
            page: 0,
            pager: [],
            loading: false,
        }

        this.fetchData = this.fetchData.bind(this)
        this.getUserSearch = this.getUserSearch.bind(this)
        this.getTypeSearch = this.getTypeSearch.bind(this)
        this.onChangePage = this.onChangePage.bind(this)
        this.setSearch = this.setSearch.bind(this)
    }

    onChangePage(number) {
        this.setState({ page: number }, this.fetchData)
    }

    fetchData() {
        let api = this.props.kedo.api()
        let params = {
            params: {
                environment: this.props.kedo.env().getEnvironment().id,
                page: this.state.page,
                dossier: this.props.dossier.id,
            },
        }

        if (!this.props.kedo.env().hasEnviroment()) {
            this.setState({
                error: this.props.kedo.t('No environment selected'),
            })
            return
        }

        if (this.state.searchUser !== null) {
            params.params.user = this.state.searchUser
        }

        if (
            this.state.searchType !== null &&
            this.state.searchType.length > 0
        ) {
            params.params.type = this.state.searchType
        }

        this.setState({ loading: true })
        api.get(api.getDossierLogEndpoint(), params).then((response) =>
            this.setState({
                logs: response.data.results,
                pager: response.data.pager,
                loading: false,
            })
        )
    }

    getChangedData(item) {
        let changes = JSON.parse(item.changes)
        const kedo = this.props.kedo

        if (item.type === 'change' && changes.length > 0) {
            return (
                <ul>
                    {changes.map((changeItem, changeIndex) => (
                        <li key={changeIndex}>
                            <strong>{changeItem.defDisplayItemName}</strong>
                            <br />
                            <s>{changeItem.oldValue}</s> <br />
                            {changeItem.newValue} <br />
                        </li>
                    ))}
                </ul>
            )
        }

        if (item.type === 'archive' && changes.length > 0) {
            return (
                <ul>
                    {changes.map((changeItem, changeIndex) => (
                        <li key={changeIndex}>{changeItem.newValue}</li>
                    ))}
                </ul>
            )
        }

        if (item.type === 'delete' && changes.length > 0) {
            return (
                <strong className={'text-danger'}>
                    {this.props.kedo.t('Deleted')}
                </strong>
            )
        }

        if (item.type === 'secureshare') {
            changes = changes[0]
            return (
                <ul>
                    {changes?.extra_data?.shareType ? (
                        <li>
                            <strong>{kedo.t('Share type')}</strong>{' '}
                            {changes.extra_data.shareType}
                        </li>
                    ) : null}
                    {changes?.extra_data?.shareValue ? (
                        <li>
                            <strong>{kedo.t('Value')}</strong>{' '}
                            {changes.extra_data.shareValue}
                        </li>
                    ) : null}
                    {changes?.extra_data?.contacts &&
                    changes.extra_data.contacts.length > 0
                        ? changes.extra_data.contacts.map((contactItem) => (
                              <li key={`${contactItem.email}`}>
                                  {contactItem.email}
                              </li>
                          ))
                        : null}
                </ul>
            )
        }

        return (
            <small className={'text-muted'}>
                {this.props.kedo.t('No changes')}
            </small>
        )
    }

    fetchUsers() {
        let params = {
            params: {
                environment: this.props.kedo.env().getEnvironment().id,
            },
        }

        this.setState({ loadUsers: true })
        this.props.kedo
            .api()
            .get(this.props.kedo.api().getUserEndpoint(), params)
            .then((response) => {
                this.setState({
                    users: response.data.results,
                    loadUsers: false,
                })
            })
    }

    getUserSearch() {
        let selectOptions = []

        if (this.state.users && this.state.users.length > 0) {
            selectOptions = this.state.users.map((item) => ({
                value: item.id,
                label: `${item.username} (${item.profile.first_name} ${item.profile.last_name})`,
            }))
        }

        return (
            <SelectRC
                className="w-100 custom-select-react"
                name={'Select User Dossierlog'}
                allowClear
                showSearch
                placeholder={this.props.kedo.t('Search') + '...'}
                value={
                    this.state.selectedUser
                        ? this.state.selectedUser.value
                        : undefined
                }
                onChange={(selectedItem) => {
                    const selectedUser = selectOptions.find(
                        (option) => option.value === selectedItem
                    )
                    this.setSearch('searchUser', selectedUser || null)
                }}
                filterOption={(input, option) =>
                    option.props.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                }
                suffixIcon={<FontAwesomeIcon icon={faChevronDown} />}
                notFoundContent={this.props.kedo.t('No users found')}
            >
                {selectOptions.map((option) => (
                    <Option key={option.value} value={option.value}>
                        {option.label}
                    </Option>
                ))}
            </SelectRC>
        )
    }

    getTypeSearch() {
        let selectOptions = [
            { value: 'shown', label: this.props.kedo.t('log_types.shown') },
            { value: 'change', label: this.props.kedo.t('log_types.change') },
            { value: 'archive', label: this.props.kedo.t('log_types.archive') },
            { value: 'delete', label: this.props.kedo.t('log_types.delete') },
            {
                value: 'secureshare',
                label: this.props.kedo.t('log_types_secureshare', {
                    ns: 'secureshare',
                }),
            },
        ]

        return (
            <>
                <SelectRC
                    className="w-100 custom-select-react"
                    name={'Select Type Dossierlog'}
                    mode="multiple"
                    allowClear
                    placeholder={this.props.kedo.t('Search') + '...'}
                    onChange={(selectedItems) => {
                        const selectedOptions = selectedItems
                            .map((selectedItem) =>
                                selectOptions.find(
                                    (option) => option.value === selectedItem
                                )
                            )
                            .filter(Boolean)

                        const selectedObject = selectedOptions[0]
                        this.setSearch('searchType', selectedObject)
                    }}
                    suffixIcon={<FontAwesomeIcon icon={faChevronDown} />}
                    notFoundContent={this.props.kedo.t('No items found')}
                >
                    {selectOptions.map((option) => (
                        <Option key={option.id} value={option.value}>
                            {option.label}
                        </Option>
                    ))}
                </SelectRC>
            </>
        )
    }

    setSearch(type, item) {
        this.setState({ [type]: item ? item.value : null }, this.fetchData)
    }

    translateType(type) {
        if (type === 'archive') {
            return this.props.kedo.t('log_types.archive')
        }
        if (type === 'change') {
            return this.props.kedo.t('log_types.change')
        }
        if (type === 'delete') {
            return this.props.kedo.t('log_types.delete')
        }
        if (type === 'shown') {
            return this.props.kedo.t('log_types.shown')
        }
        if (type === 'secureshare') {
            return this.props.kedo.t('log_types_secureshare', {
                ns: 'secureshare',
            })
        }
    }

    componentDidMount() {
        this.fetchData()
        this.fetchUsers()
    }

    render() {
        const kedo = this.props.kedo

        return (
            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={true}
                onHide={() => this.props.onClose()}
                size="xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {kedo.t('Logs')}: {this.props.dossier.summary}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12}>
                            <h5>{kedo.t('Search')}</h5>
                            <Form.Group>
                                <Form.Label>{kedo.t('Type')}</Form.Label>
                                {this.getTypeSearch()}
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>{kedo.t('Users')}</Form.Label>
                                {this.getUserSearch()}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Table striped>
                        <thead>
                            <tr>
                                <th>{kedo.t('Date')}</th>
                                <th>{kedo.t('Type')}</th>
                                <th>{kedo.t('User')}</th>
                                <th>{kedo.t('Changes')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.loading ? (
                                <tr>
                                    <td colSpan={4}>
                                        <Loading />
                                    </td>
                                </tr>
                            ) : this.state.logs &&
                              this.state.logs.length > 0 ? (
                                this.state.logs.map((item) => {
                                    return (
                                        <tr key={item.id}>
                                            <td>
                                                {kedo
                                                    .utils()
                                                    .dateFormat(item.datetime)}
                                            </td>
                                            <td>
                                                {this.translateType(item.type)}
                                            </td>
                                            <td>
                                                <a
                                                    href={`/user/${item.user_id}`}
                                                >
                                                    {item.username
                                                        ? item.username
                                                        : item.user_id}
                                                </a>
                                            </td>
                                            <td>{this.getChangedData(item)}</td>
                                        </tr>
                                    )
                                })
                            ) : (
                                <tr>
                                    <td colSpan={4}>
                                        <Alert variant={'info'}>
                                            <FontAwesomeIcon
                                                icon={faExclamationCircle}
                                            />
                                            &nbsp; {kedo.t('No items found')}
                                        </Alert>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan={4}>
                                    <Pagination
                                        pager={this.state.pager}
                                        onChangePage={this.onChangePage}
                                    />
                                    <ItemCounter pager={this.state.pager} />
                                </td>
                            </tr>
                        </tfoot>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => this.props.onClose()}
                        variant="secondary"
                        title={kedo.t('Close')}
                    >
                        {kedo.t('Close')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default ContentDossierLog
