import Api from './Api'
import EnvironmentUtil from './EnvironmentUtil'
import User from './User'
import Utils from './Utils'
import Socket from './Socket'
import Title from './Title'

class Kedo {
    constructor() {
        this._api = new Api()
        this._env = new EnvironmentUtil()
        this._user = new User()
        this._utils = new Utils()
        this._title = new Title()
        this._socket = new Socket()
    }

    getLanguage() {
        return this._utils.i18n.language || window.localStorage.i18nextLng
    }

    getCulture() {
        let language = this.getLanguage()

        if (language === 'nl') {
            return 'nl_NL'
        } else if (language === 'es') {
            return 'es_ES'
        } else if (language === 'en') {
            return 'en_GB'
        } else if (language === 'ru') {
            return 'ru_RU'
        } else if (language === 'de') {
            return 'de-de'
        } else if (language === 'fr') {
            return 'fr-FR'
        }

        return 'en_GB'
    }

    /**
     * @returns {null|int}
     */
    getCurrentEnvironmentId() {
        if (!this.env().getEnvironment()) {
            return null
        }

        return this.env().getEnvironment().id
    }

    isAdmin() {
        if (this._user.isSuperAdmin()) {
            return true
        }

        return false
    }

    isEnvironmentAdmin() {
        if (this._env.isEnvironmentAdmin()) {
            return true
        }

        return false
    }

    isContractAdmin() {
        if (this._user.isContractAdminUser()) {
            return true
        }

        return false
    }

    isAdminOrEnvironmentAdmin() {
        if (this._user.isSuperAdmin()) {
            return true
        }
        if (this._env.isEnvironmentAdmin()) {
            return true
        }

        return false
    }

    isAdminOrContractAdmin() {
        if (this._user.isSuperAdmin()) {
            return true
        }
        if (this._user.isContractAdminUser()) {
            return true
        }

        return false
    }

    api() {
        return this._api
    }

    env() {
        return this._env
    }

    user() {
        return this._user
    }

    utils() {
        return this._utils
    }

    socket() {
        return this._socket
    }

    translateItem(item, itemType, plural = false) {
        if (item === null) {
            return ''
        }
        return this._env.translateItem(item, itemType, plural)
    }

    t(trans, options) {
        return this._utils.translate(trans, options)
    }

    title() {
        return this._title
    }
}

export default Kedo
export const kedoInstance = new Kedo()
