import React, { Component } from 'react'
import {
    Button,
    ButtonToolbar,
    ButtonGroup,
    Col,
    Row,
    Alert,
    Badge,
} from 'react-bootstrap'
import DefDossierKanbanColumn from './DefDossierKanbanColumn'
import Loading from '../../Elements/Loading/LoadingContent'
import { DragDropContext } from 'react-beautiful-dnd'
import styled from 'styled-components'
import SearchDefDossier from './SearchDefDossier'

import { faFilter } from '@fortawesome/pro-duotone-svg-icons/faFilter'
import { faTimes } from '@fortawesome/pro-duotone-svg-icons/faTimes'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LoadingDefault from '../../Elements/Loading/LoadingDefault'

const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
`

class DefDossierKanban extends Component {
    constructor(props) {
        super(props)
        let sortOptions = []
        if (this.props.ddiSort) {
            for (let i = 0; i < this.props.ddiSort.length; i++) {
                sortOptions.push({
                    order: this.props.ddiSort[i].sort,
                    item: {
                        def_dossier_def_field: {
                            id: parseInt(this.props.ddiSort[i].dddfId),
                        },
                    },
                })
            }
        }
        this.state = {
            kanban_custom_view: null,
            displayItem: null,
            search: null,
            sort: sortOptions,
            showFilter: false,
            searchItems: [],
            hiddenColumns: [],
            loading: false,
            defDossier: null,
            refs: [],
        }

        this.createColumn = this.createColumn.bind(this)
        this.hideColumn = this.hideColumn.bind(this)
        this.doSearch = this.doSearch.bind(this)
    }

    //Update state of our components
    onDragEnd = (result) => {
        const { destination, source, draggableId, type } = result

        if (!destination) {
            return
        }

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return
        }

        if (destination.droppableId === source.droppableId) {
            return
        }

        this.state.refs
            .find((refItem) => refItem.id === parseInt(source.droppableId))
            .current.removeDossier(draggableId)
            .then(
                //Result is our deleted dossier from source column
                (result) => {
                    this.state.refs
                        .find(
                            (refItem) =>
                                refItem.id === parseInt(destination.droppableId)
                        )
                        .current.addDossier(result)
                }
            )
    }

    createColumn() {
        if (
            !this.state.displayItem ||
            !this.state.displayItem.def_dossier_def_field ||
            !this.state.displayItem.def_dossier_def_field.def_field ||
            !this.state.displayItem.def_dossier_def_field.def_field
                .def_field_lists
        ) {
            return ''
        }

        return this.state.displayItem.def_dossier_def_field.def_field.def_field_lists.map(
            (item) => {
                if (
                    !this.state.refs.find((refItem) => refItem.id === item.id)
                ) {
                    let allRefs = this.state.refs
                    allRefs.push({ id: item.id, ref: React.createRef() })
                    this.setState({ refs: allRefs })
                }

                if (this.state.hiddenColumns.includes(item.id)) {
                    return null
                }

                return (
                    <DefDossierKanbanColumn
                        ref={this.state.refs.find(
                            (refItem) => refItem.id === item.id
                        )}
                        key={item.id}
                        onHideColumn={() => this.hideColumn(item)}
                        kedo={this.props.kedo}
                        defFieldList={item}
                        sort={this.state.sort}
                        embedded={this.props.embedded}
                        linkId={this.props.linkId}
                        onRefresh={() => {
                            this.state.refs.map((refItem) => {
                                if (
                                    !this.state.hiddenColumns.includes(
                                        refItem.id
                                    )
                                ) {
                                    refItem.current.doSearch(
                                        this.state.search,
                                        this.state.dossierQuery
                                    )
                                }
                            })
                        }}
                        defDossier={this.state.defDossier}
                        displayItem={this.state.displayItem}
                        onClick={this.props.onClick}
                        kanbanCustomView={this.state.kanban_custom_view}
                    />
                )
            }
        )
    }

    fetchSearchFields(ddId) {
        let api = this.props.kedo.api()
        let params = {
            params: {
                defDossier: ddId,
                view: ['search', 'advanced-search'],
            },
        }
        this.setState({ searchLoading: true })
        api.getCached(api.getDisplayItemEndpoint(), params).then((response) => {
            this.setState({
                searchLoading: false,
                searchItems: response.data.results.filter(
                    (item) =>
                        !item.def_dossier_def_field ||
                        item.def_dossier_def_field.id !==
                            this.state.displayItem.def_dossier_def_field.id
                ),
            })
        })
    }

    fetchDefDossier() {
        let api = this.props.kedo.api()
        let params = {
            params: {
                defDossier: this.props.defDossierId,
                limit: 1,
            },
        }

        this.setState({ loading: true })
        api.getCached(
            api.getDefDossierEndpoint(this.props.defDossierId),
            params
        ).then((response) => {
            if (response.data.settings.kanban_search_field) {
                if (response.data.settings.kanban_list_field) {
                    if (
                        response.data.settings.kanban_custom_view != 'default'
                    ) {
                        this.setState({
                            kanban_custom_view:
                                response.data.settings.kanban_custom_view,
                        })
                    }

                    api.getCached(
                        api.getDisplayItemEndpoint(
                            response.data.settings.kanban_search_field
                        )
                    ).then((ddiResponse) => {
                        this.setState(
                            {
                                defDossier: response.data,
                                displayItem: ddiResponse.data,
                                loading: false,
                            },
                            () =>
                                this.fetchSearchFields(this.props.defDossierId)
                        )
                    })
                } else {
                    this.setState({
                        defDossier: response.data,
                        loading: false,
                        kanbanListFieldError: true,
                    })
                }
            } else {
                this.setState({
                    defDossier: response.data,
                    loading: false,
                    kanbanSearchFieldError: true,
                })
            }
        })
    }

    componentDidMount() {
        this.fetchDefDossier()
    }

    hideColumn(item) {
        let hiddenColumns = this.state.hiddenColumns.filter(
            (column) => column !== item.id
        )
        hiddenColumns.push(item.id)
        this.setState({ hiddenColumns: hiddenColumns })
    }

    doSearch(searchParams, dossierQuery) {
        this.setState(
            {
                search: searchParams,
                dossierQuery: dossierQuery,
                showFilter: false,
            },
            () => {
                this.state.refs.map((refItem) => {
                    if (!this.state.hiddenColumns.includes(refItem.id)) {
                        refItem.current.doSearch(searchParams, dossierQuery)
                    }
                })
            }
        )
    }

    render() {
        if (this.state.loading) {
            return <Loading />
        }

        if (this.state.kanbanSearchFieldError) {
            return (
                <Alert variant={'warning'}>
                    Contact your administrator, no kanban search field set.
                </Alert>
            )
        }

        if (this.state.kanbanListFieldError) {
            return (
                <Alert variant={'warning'}>
                    Contact your administrator, no kanban list field set.
                </Alert>
            )
        }

        return (
            <Row className={'d-flex align-items-stretch'}>
                <Col xs={12}>
                    <ButtonToolbar
                        className={'float-right'}
                        aria-label="Toolbar with button groups"
                    >
                        <ButtonGroup
                            className="mr-2"
                            aria-label="Hidden columns"
                        >
                            {this.state.hiddenColumns.map(
                                (hiddenItem, hIndex) => (
                                    <Button
                                        key={hIndex}
                                        className={'mr-1'}
                                        size={'sm'}
                                        onClick={() => {
                                            let columns =
                                                this.state.hiddenColumns.filter(
                                                    (column) =>
                                                        column !== hiddenItem
                                                )
                                            this.setState({
                                                hiddenColumns: columns,
                                            })
                                        }}
                                    >
                                        <Badge>
                                            {this.props.kedo.translateItem(
                                                this.state.displayItem.def_dossier_def_field.def_field.def_field_lists.find(
                                                    (item) =>
                                                        item.id === hiddenItem
                                                ),
                                                'def_field_list'
                                            )}{' '}
                                            <FontAwesomeIcon icon={faTimes} />
                                        </Badge>
                                    </Button>
                                )
                            )}
                        </ButtonGroup>
                        <ButtonGroup className="ml-2" aria-label="Show filter">
                            {this.state.searchLoading ? (
                                <Button>
                                    <LoadingDefault />
                                </Button>
                            ) : (
                                <Button
                                    active={this.state.showFilter}
                                    onClick={() =>
                                        this.setState({
                                            showFilter: !this.state.showFilter,
                                        })
                                    }
                                >
                                    <FontAwesomeIcon icon={faFilter} />
                                </Button>
                            )}
                        </ButtonGroup>
                    </ButtonToolbar>
                </Col>
                <Col xs={12}>
                    {this.state.showFilter ? (
                        <SearchDefDossier
                            search={this.state.search}
                            doSearch={this.doSearch}
                            kedo={this.props.kedo}
                            resetSearch={() => this.setState({ search: null })}
                            items={this.state.searchItems}
                        />
                    ) : null}
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Container className="unselectable kanban-overall-container">
                            {this.createColumn()}
                        </Container>
                    </DragDropContext>
                </Col>
            </Row>
        )
    }
}

export default DefDossierKanban
