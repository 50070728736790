import React, { Component } from 'react'
import LoadingDefault from '../../Elements/Loading/LoadingDefault'
import styled from 'styled-components'
import { Draggable } from 'react-beautiful-dnd'
import DefaultGrid from '../../Grid/DefaultGrid'
import DossierModalEditButton from '../../Elements/DossierModalEditButton'

const Container = styled.div`
    border-bottom: 3px solid rgb(122 142 155);
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 8px;
    transition: background-color 0.2s ease;
    background-color: ${(props) =>
        props.isDragging
            ? 'rgba(220, 236, 247, 1)'
            : 'rgba(220, 236, 247, .9)'};
    box-shadow: ${(props) =>
        props.isDragging
            ? '0 3px 6px 0 rgb(0 0 0 / 10%), 0 6px 20px 0 rgb(0 0 0 / 10%)'
            : 'unset'};
    font-weight: 500;
`

const preventDefault = (e) => {
    e.preventDefault()
}

class DefDossierKanbanCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            inlineEdit: false,
        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(e) {
        preventDefault(e)

        if (e.button === 2) {
            this.handleContent(e, this.props.dossier)
        }
    }

    handleContent(event, dossier) {
        //event.stopPropagation();
        // let trigger = event.target;
        // if (trigger.nodeName === "INPUT" && trigger.className.indexOf('inline-edit') === -1) {
        //     return
        // }
        //
        // if (event.ctrlKey || (navigator.platform == 'MacIntel' && event.metaKey)) {
        //     window.open(`/contentdossier/${dossier.id}`, '_blank');
        //     return
        // }
        // this.props.onClick(dossier);
        // this.toTop();
    }

    toTop() {
        window.scrollTo(0, 0)
    }

    getContentByView(content, displayItems, viewName) {
        //filter the display items
        let returnContent = {}

        let filterItems = displayItems.filter(
            (displayItem) => displayItem.view === viewName
        )

        if (filterItems.length >= 0) {
            filterItems.map((displayItem, index) => {
                content[index]
                    ? (returnContent[displayItem.id] =
                          content[index].content.content)
                    : null
            })
        }

        return returnContent
    }

    render() {
        return (
            <Draggable
                draggableId={this.props.dossier.id.toString()}
                index={this.props.index}
            >
                {(provided, snapshot) => (
                    <Container
                        className={'kanban-card-container'}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        isDragging={snapshot.isDragging}
                        onContextMenu={preventDefault}
                        onMouseDown={this.handleChange}
                    >
                        {this.props.kanbanCustomView ? (
                            <>
                                {this.props.isLoading ===
                                this.props.dossier.id ? (
                                    <div className={'kanban-loader'}>
                                        <LoadingDefault size="lg" />
                                    </div>
                                ) : (
                                    <div className={'kanban-mg-25'}>
                                        <DossierModalEditButton
                                            size={'xl'}
                                            showSaveClose={true}
                                            onSuccess={this.props.onRefresh}
                                            dossierId={this.props.dossier.id}
                                            kedo={this.props.kedo}
                                        />
                                        <DefaultGrid
                                            key={this.props.dossier.id}
                                            defDossierSettings={
                                                this.props.defdossier &&
                                                this.props.defdossier.settings
                                                    ? this.props.defdossier
                                                          .settings
                                                    : null
                                            }
                                            conditions={
                                                this.props.defdossier &&
                                                this.props.defdossier
                                                    .settings &&
                                                this.props.defdossier.settings
                                                    .conditions
                                                    ? this.props.defdossier
                                                          .settings.conditions
                                                    : []
                                            }
                                            content={this.getContentByView(
                                                this.props.content.filter(
                                                    (contentItem) =>
                                                        contentItem.id ===
                                                        this.props.dossier.id
                                                ),
                                                this.props.displayItems,
                                                this.props.kanbanCustomView
                                            )}
                                            dossier={this.props.dossier}
                                            hiddenfields={
                                                this.props.defdossier &&
                                                this.props.defdossier
                                                    .settings &&
                                                this.props.defdossier.settings
                                                    .hiddenfields
                                                    ? this.props.defdossier
                                                          .settings.hiddenfields
                                                    : []
                                            }
                                            items={this.props.displayItems.filter(
                                                (displayItem) =>
                                                    displayItem.view ===
                                                    this.props.kanbanCustomView
                                            )}
                                            kedo={this.props.kedo}
                                            mode={this.props.kanbanCustomView}
                                            withoutEdit={true}
                                        />
                                    </div>
                                )}
                            </>
                        ) : (
                            <p className={'kanban-card-content'}>
                                {this.props.dossier.summary}
                                <DossierModalEditButton
                                    size={'xl'}
                                    showSaveAndClose={true}
                                    onSuccess={this.props.onRefresh}
                                    dossierId={this.props.dossier.id}
                                    kedo={this.props.kedo}
                                />
                            </p>
                        )}
                    </Container>
                )}
            </Draggable>
        )
    }
}

export default DefDossierKanbanCard
